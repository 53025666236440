import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgMail32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="mail-32_svg__a" d="M27.969 8.458v-.029l-.005-.007a.66.66 0 0 0-.28-.317.65.65 0 0 0-.339-.105H4.667a.641.641 0 0 0-.397.145.66.66 0 0 0-.218.265v.006A.662.662 0 0 0 4 8.667v14.665a.666.666 0 0 0 .667.668h22.678a.666.666 0 0 0 .667-.667V8.667a.665.665 0 0 0-.042-.21zM5.334 10.176c1.71 1.532 4.9 4.348 6.915 6.126l-6.915 5.529zm7.93 7.021.792.7a2.875 2.875 0 0 0 1.959.766 2.909 2.909 0 0 0 1.9-.665l.887-.793 6.698 5.463H6.423l6.841-5.47zm6.549-.9 6.866-6.141v11.751L19.813 16.3zM25.6 9.335c-2.694 2.411-8.561 7.656-8.559 7.656a1.647 1.647 0 0 1-2.1-.094c-2.4-2.113-6.48-5.714-8.548-7.561z" /></defs><g fill="none" fillRule="evenodd"><mask id="mail-32_svg__b" fill="#fff"><use xlinkHref="#mail-32_svg__a" /></mask><g fill="#000" mask="url(#mail-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgMail32);
export default Memo;